import type { NextPage } from 'next';
import _ from 'lodash';
import Image from 'next/image';
import underConstructionImage from '../../public/images/under-construction.png';
import { getStaticPropsNicho, NichosStaticProps } from 'ui';

type Props = {};

const Home: NextPage<Props> = ({}: Props & NichosStaticProps) => {
	return (
		<div className="flex flex-col">
			<section className="flex pt-10">
				<div className=" flex-col">
					<h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
						En construcción
					</h2>
					<Image src={underConstructionImage} alt="AltairStudios - Estamos trabajando en ello" />
				</div>
			</section>
		</div>
	);
};

export const getStaticProps = getStaticPropsNicho(() => {
	return {
		props: {
			title: 'AltairStudios'
		}
	};
});

export default Home;
